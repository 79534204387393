import React, { useEffect, useState } from "react"
import ItemModal from "./itemModal"
import { Highlight } from 'react-instantsearch-dom';
import { monthStrings } from './utilities';
import { useLocation } from "@reach/router"
import qs from 'qs';

const IndexSearchHits = ({ hit, index }) => {
  const location = useLocation();
  const urlToSearchState = ({ search }) => qs.parse(search.slice(1));
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (urlToSearchState(location).showModal && (urlToSearchState(location).showModal === 'true') !== showModal) {
      setShowModal(urlToSearchState(location).showModal === 'true')
    }
  }, [location])

  switch(index) {
    case 'article':
      return (
        <>
          <li
            className={"faux-link"}
            onClick={() => {
              if (!showModal) {
                setShowModal(true)
              }
            }}
          >
            { hit.title &&
              <>
                <Highlight attribute="title" hit={hit} /> (<cite>{hit.periodicals?.join(", ")}</cite>, {hit.day} {monthStrings[hit.month_id]} {hit.article_year})
              </>
            }
          </li>
          { showModal &&
            <ItemModal
              selection={{ index: 'article', id: hit.id }}
              onClose={() => setShowModal(false)}
              showModal={showModal}
            />
          }
        </>
      );
    case 'contributor':
      return (
        <>
          <li
            className={"faux-link"}
            onClick={() => {
              if (!showModal) {
                setShowModal(true)
              }
            }}
          >
            { hit.full_name &&
              <>
              <Highlight attribute="full_name" hit={hit} /> ({hit.birth_year || "?"} - {hit.death_year || "?"})
              </>
            }
          </li>
          { showModal &&
            <ItemModal
              selection={{ index: 'contributor', id: hit.id }}
              onClose={() => setShowModal(false)}
              showModal={showModal}
            />
          }
        </>
      );
    case 'periodical':
      return (
        <>
          <li
            className={"faux-link"}
            onClick={() => {
              if (!showModal) {
                setShowModal(true)
              }
            }}
          >
            {hit.title &&
              <>
                <Highlight attribute="title" hit={hit} /> ({hit.abbreviation})
              </>
            }
          </li>
          { showModal &&
            <ItemModal
              selection={{ index: 'periodical', id: hit.id }}
              onClose={() => setShowModal(false)}
              showModal={showModal}
            />
          }
        </>
      );
    default:
      return null;
  }
}

export default IndexSearchHits
