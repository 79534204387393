import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  Header,
  Input
} from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

const DateRangeInput = ({ currentRefinement, refine }) => {
  const [startYear, setStartYear] = useState('');
  const [startMonth, setStartMonth] = useState('');
  const [startDay, setStartDay] = useState('');
  const [endYear, setEndYear] = useState('');
  const [endMonth, setEndMonth] = useState('');
  const [endDay, setEndDay] = useState('');

  const handleSubmit = () => {
    refine({
      ...currentRefinement,
      min: new Date(`${startYear || '0000'}-${startMonth || '01'}-${startDay || '01'}`).valueOf(),
      max: new Date(`${endYear || '9999'}-${endMonth || '12'}-${endDay || '31'}`).valueOf()
    })
  };

  useEffect(() => {
    if (!currentRefinement.min) {
      setStartYear('');
      setStartMonth('');
      setStartDay('');
    }
    if (!currentRefinement.max) {
      setEndYear('');
      setEndMonth('');
      setEndDay('');
    }
  },[currentRefinement])


  return (
    <>
      <Header as="h5" style={{margin: "15px 0px"}}>
        Article Date:
      </Header>
      <Form className="date-filter-form">
        <Form.Group inline>
          <Input
            className="date-filter-inputs"
            label='Year'
            placeholder='YYYY'
            onChange={(event, data) => setStartYear(data.value)}
            value={startYear}
            size="mini"
          />
          <Input
            className="date-filter-inputs"
            label='Month'
            placeholder='MM'
            onChange={(event, data) => setStartMonth(data.value)}
            value={startMonth}
            size="mini"
          />
          <Input
            className="date-filter-inputs"
            label='Day'
            placeholder='DD'
            onChange={(event, data) => setStartDay(data.value)}
            value={startDay}
            size="mini"
          />
        </Form.Group>
        <div style={{marginBottom: "1.25em"}}> to </div>
        <Form.Group inline>
          <Input
            className="date-filter-inputs"
            label='Year'
            placeholder='YYYY'
            onChange={(event, data) => setEndYear(data.value)}
            value={endYear}
            size="mini"
          />
          <Input
            className="date-filter-inputs"
            label='Month'
            placeholder='MM'
            onChange={(event, data) => setEndMonth(data.value)}
            value={endMonth}
            size="mini"
          />
          <Input
            className="date-filter-inputs"
            label='Day'
            placeholder='DD'
            onChange={(event, data) => setEndDay(data.value)}
            value={endDay}
            size="mini"
          />
        </Form.Group>
        <Button
          basic
          onClick={handleSubmit}
          size="mini"
        >
          OK
        </Button>
      </Form>
    </>
  );
};

export default DateRangeInput
