import React from "react"
import {
  capitalize
} from "../components/utilities"
import DateRangeInput from "../components/dateRangeInput"
import 'semantic-ui-css/semantic.min.css'
import {
  Header,
  Segment
} from 'semantic-ui-react'
import {
  connectRange,
  RangeInput,
  RefinementList
} from 'react-instantsearch-dom';

const CustomRangeInput = connectRange(DateRangeInput);

const IndexSearchFilters = ({ index }) => {
  switch(index) {
    case 'article':
      return (
        <Segment>
          <Header as="h5" style={{margin: "15px 0px"}}>Article Type</Header>
          <RefinementList
            attribute="article_types"
            showMore
            showMoreLimit={100}
            transformItems={items => items.sort((a, b) => a.label > b.label ? 1 : -1)
              .map(item => ({
                ...item,
                label: capitalize(item.label)
              }))
            }
          />
          <Header as="h5" style={{margin: "30px 0px 15px"}}>Article Year</Header>
          <RangeInput
            attribute="article_year"
            showMore
            showMoreLimit={100}
            translations={{
              submit: 'OK'
            }}
          />
          <CustomRangeInput attribute="date" />
          <Header as="h5" style={{margin: "30px 0px 15px"}}>Periodical</Header>
          <RefinementList
            attribute="periodicals"
            showMore
            showMoreLimit={100}
            transformItems={items => items.sort((a, b) => a.label > b.label ? 1 : -1)
              .map(item => ({
                ...item,
                label: capitalize(item.label)
              }))
            }
          />
        </Segment>
      );
    case 'contributor':
      return (
        <Segment>
          <Header as="h5" style={{margin: "15px 0px"}}>Gender</Header>
          <RefinementList
            attribute="gender"
            showMore
            showMoreLimit={100}
            transformItems={items => items.sort((a, b) => a.label > b.label ? 1 : -1)
              .map(item => ({
                ...item,
                label: capitalize(item.label)
              }))
            }
          />
          <Header as="h5" style={{margin: "30px 0px 15px"}}>Nationality</Header>
          <RefinementList
            attribute="nationalities"
            showMore
            showMoreLimit={100}
            transformItems={items => items.sort((a, b) => a.label > b.label ? 1 : -1)
              .map(item => ({
                ...item,
                label: capitalize(item.label)
              }))
            }
          />
          <Header as="h3">Education</Header>
          <RefinementList
            attribute="education"
            showMore
            showMoreLimit={100}
            transformItems={items => items.sort((a, b) => a.label > b.label ? 1 : -1)
              .map(item => ({
                ...item,
                label: capitalize(item.label)
              }))
            }
          />
          <Header as="h5" style={{margin: "30px 0px 15px"}}>Birth Year</Header>
          <RangeInput
            attribute="birth_year"
            translations={{
              submit: 'OK'
            }}
          />
          <Header as="h5" style={{margin: "30px 0px 15px"}}>Death Year</Header>
          <RangeInput
            attribute="death_year"
            translations={{
              submit: 'OK'
            }}
          />
        </Segment>
      );
    case 'periodical':
      return (
        <Segment>
          <Header as="h5" style={{margin: "15px 0px"}}>Frequency</Header>
          <RefinementList
            attribute="frequencies"
            showMore
            showMoreLimit={100}
            transformItems={items => items.sort((a, b) => a.label > b.label ? 1 : -1)
              .map(item => ({
                ...item,
                label: capitalize(item.label)
              }))
            }
          />
        </Segment>
      );
    default:
      return null;
  }
}

export default IndexSearchFilters
