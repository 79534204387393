import React, { useState, useEffect } from "react"
import { fetchPageContents } from "../components/utilities"
import { WelcomeModalContext } from "../../provider";
import {
  Button,
  Modal
} from 'semantic-ui-react'

const WelcomeModal = () => {

  const [pageContents, setPageContents] = useState("");

  useEffect(() => {
    fetchPageContents("home")
      .then(text => setPageContents(text));
  },[]);

  return (
    <WelcomeModalContext.Consumer>
      { context => (
        <Modal
          onClose={context.closeWelcomeModal}
          open={context.showWelcomeModal}
        >
          <Modal.Content>
            <div
              dangerouslySetInnerHTML={{__html: pageContents}}>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button color='black' onClick={context.closeWelcomeModal}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </WelcomeModalContext.Consumer>
  );
}

export default WelcomeModal
