import React, { useState } from "react"
import { capitalize } from "../components/utilities"
import CsvDownloadButton from "../components/csvDownloadButton"
import IndexSearchFilters from "../components/indexSearchFilters"
import IndexSearchHits from "../components/indexSearchHits"
import Layout from "../components/layout"
import Seo from "../components/seo"
import WelcomeModal from "../components/welcomeModal"
import {
  Accordion,
  Form,
  Header,
  Icon,
  Radio
} from 'semantic-ui-react'
import {
  ClearRefinements,
  connectStateResults,
  CurrentRefinements,
  Hits,
  InstantSearch,
  Pagination,
  SearchBox,
  SortBy
} from 'react-instantsearch-dom';

import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';

const searchClient = instantMeiliSearch(
  "https://admin.curranindex.org/data",
  "",
  {
    finitePagination: true,
    paginationTotalHits: 9999,
    primaryKey: 'id',
  }
);

const IndexPage = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState('article');

  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex);
  }

  const handleRadioChange = (e, { value }) => {
    setSelectedIndex(value);
  }

  const sortByValue = {
    "article": [
      { label: 'Sort by Article Date (Ascending)', value: 'articles:date:asc' },
      { label: 'Sort by Article Date (Descending)', value: 'articles:date:desc' },
      { label: 'Sort by Title (Ascending)', value: 'articles:title:asc' },
      { label: 'Sort by Title (Descending)', value: 'articles:title:desc' }
    ],
    "contributor": [
      { label: 'Sort by Name (Ascending)', value: 'contributors:full_name:asc' },
      { label: 'Sort by Name (Descending)', value: 'contributors:full_name:desc' },
      { label: 'Sort by Birth Year (Ascending)', value: 'contributors:birth_year:asc' },
      { label: 'Sort by Birth Year (Descending)', value: 'contributors:birth_year:desc' },
      { label: 'Sort by Death Year (Ascending)', value: 'contributors:death_year:asc' },
      { label: 'Sort by Death Year (Descending)', value: 'contributors:death_year:desc' }
    ],
    "periodical": [
      { label: 'Sort by Title (Ascending)', value: 'periodicals:title:asc' },
      { label: 'Sort by Title (Descending)', value: 'periodicals:title:desc' },
      { label: 'Sort by Earliest Year (Ascending)', value: 'periodicals:earliest_article:asc' },
      { label: 'Sort by Earliest Year (Descending)', value: 'periodicals:earliest_article:desc' },
      { label: 'Sort by Latest Year (Ascending)', value: 'periodicals:latest_article:asc' },
      { label: 'Sort by Latest Year (Descending)', value: 'periodicals:latest_article:desc' }
    ]

  }

  const Hit = ({ hit }) => (
    <IndexSearchHits
      hit={hit}
      index={selectedIndex}
    />
  )

  const SearchResultsCount = ({ searchResults }) => (
    <div>
      { searchResults &&
        <h4>{ (searchResults.nbHits).toLocaleString() } results</h4>
      }
    </div>
  );

  const DisplaySearchResultsCount = connectStateResults(SearchResultsCount);

  const CsvDownload = ({ searchState }) => (
    <CsvDownloadButton
      index={selectedIndex}
      searchState={searchState}
    />
  );

  const DisplayCsvDownloadButton = connectStateResults(CsvDownload);

  return (
    <Layout>
      <Seo title="The Curran Index to Nineteenth-Century Periodicals" />
      <InstantSearch
        indexName={`${selectedIndex}s`}
        searchClient={searchClient}
      >
        <div>
          <div align="center">&nbsp;</div>

          <div style={{display: "flex", justifyContent: "space-between", flexDirection: "column"}}>

            <div>
              <div className="home-search" style={{marginTop: "20px", height: "100%"}}>
                <Header as="h2">Search {capitalize(selectedIndex)}s</Header>
                <SearchBox
                  searchAsYouType={false}
                />

                <Form>
                  <Form.Field>
                    <Radio
                      label='Articles'
                      name='radioGroup'
                      value='article'
                      checked={selectedIndex === 'article'}
                      onChange={handleRadioChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label='Contributors'
                      name='radioGroup'
                      value='contributor'
                      checked={selectedIndex === 'contributor'}
                      onChange={handleRadioChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label='Periodicals'
                      name='radioGroup'
                      value='periodical'
                      checked={selectedIndex === 'periodical'}
                      onChange={handleRadioChange}
                    />
                  </Form.Field>
                </Form>

                <DisplaySearchResultsCount />
                <CurrentRefinements />
                <ClearRefinements />
                <SortBy
                  defaultRefinement={sortByValue[selectedIndex][0].value}
                  items={sortByValue[selectedIndex]}
                />
                <div style={{display: "flex"}}>
                  <div className={"home-filters"}>
                    <DisplayCsvDownloadButton />
                    <Accordion>
                      <Accordion.Title
                        active={activeIndex === 1}
                        index={1}
                        onClick={handleClick}
                      >
                        <Icon name='dropdown' />
                        <Header as="h5">Filters</Header>
                      </Accordion.Title>
                      <Accordion.Content active={activeIndex === 1}>
                        <IndexSearchFilters index={selectedIndex} />
                      </Accordion.Content>
                    </Accordion>
                  </div>
                  <div className={"home-results"}>
                    <Header as="h5" style={{padding: "7px"}}>Results</Header>
                    <div className="results">
                      <Hits className={"list"} hitComponent={Hit} />
                      <Pagination />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </InstantSearch>
      <WelcomeModal />
    </Layout>
  )
}

export default IndexPage
